
// Names,categories and types of visual channels, based on the literature
export const channels = {
  positionX: {name: 'Position X', category: 'Spatial position', type: ['quantitative', 'nominal', 'ordinal'], range: 'large', bothMantExp: true},
  positionY: {name: 'Position Y', category: 'Spatial position', type: ['quantitative', 'nominal', 'ordinal'], range: 'large', bothMantExp: true},
  column: {name: 'Column', category: 'Spatial position', type: ['quantitative', 'nominal', 'ordinal'], range: 'small', bothMantExp: false},
  row: {name: 'Row', category: 'Spatial position', type: ['quantitative', 'nominal', 'ordinal'], range: 'small', bothMantExp: false},
  length: {name: 'Length', category: 'Spatial size', type: ['quantitative', 'ordinal'], range: 'large', bothMantExp: false},
  area: {name: 'Area', category: 'Spatial size', type: ['quantitative', 'ordinal'], range: 'large', bothMantExp: false},
  // angle: {name: 'Angle', category: 'Spatial size', type: ['quantitative', 'ordinal'], range: 'large', bothMantExp: false},
  // curvature: {name: 'Curvature', category: 'Spatial size', type: ['quantitative', 'ordinal'], range: 'large', bothMantExp: false},
  // units: {name: 'Units', category: 'Countable marks', type: ['quantitative', 'ordinal'], range: 'medium', bothMantExp: false},
  // countTexture: {name: 'Countable Texture', category: 'Countable marks', type: ['quantitative', 'ordinal'], range: 'small', bothMantExp: false},
  // countShape: {name: 'Countable Shape', category: 'Countable marks', type: ['quantitative', 'ordinal'], range: 'small', bothMantExp: false},
  // countSymbol: {name: 'Countable Symbol', category: 'Countable marks', type: ['quantitative', 'ordinal'], range: 'small', bothMantExp: false},
  colorIntensity: {name: 'Color Intensity', category: 'Color Intensity', type: ['quantitative', 'ordinal'], range: 'small', bothMantExp: false},
  colorHue: {name: 'Color Hue', category: 'Identity channels', type: ['nominal'], range: 'large', bothMantExp: false},
  shape: {name: 'Shape', category: 'Identity channels', type: ['nominal'], range: 'small', bothMantExp: false},
  // texture: {name: 'Texture', category: 'Identity channels', type: ['nominal'], range: 'small', bothMantExp: false},
  // orientation: {name: 'Orientation', category: 'Identity channels', type: ['nominal'], range: 'small', bothMantExp: false},
};


const colorPalettes = {colorIntensity: 'Purples', colorHue: 'pastel2'};

// Extracting unique values for the 'name' key
const uniqueCategoriesSet = new Set(Object.values(channels).map(((item) => item.category)));

export function isBothMantExpValue(channelKey) {
  // Check if the channel exists in the channels object
  if (channels.hasOwnProperty(channelKey)) {
    // Return the bothMantExp value of the given channel key
    return channels[channelKey].bothMantExp;
  } else {
    // Return undefined or a suitable default value if the channel does not exist
    console.error('Channel does not exist:', channelKey);
    return undefined; // Or return a default value/false if that suits your application logic
  }
}

// Converting Set to an array
export const categories = Array.from(uniqueCategoriesSet);

// return the name of channel
export const getNameofChannel = (ch) => {
  const n = channels[ch].name;
  return n;
};

// return the channels per category
export const getChannelsPerCategory = (cat) => {
  const ch = Object.keys(channels).filter((key) => channels[key].category === cat);
  return ch;
};

// Find and return the data type of a selected column
export const returnDataType = (key, columns) => {
  const foundObject = columns.find((obj) => obj.key === key);
  return foundObject ? foundObject.type : 'Not Found';
};

// Apply hard constraints based on the literature of the expressiveness and effectiveness of visual variables
export const checkForHardConstraints = (ch, column, mark, digitsMantissa) => {
  const disable = false;
  let message = '';
  if (channels.hasOwnProperty(ch)) {
    const channel = channels[ch];
    const channelTypes = [...channel.type];
    const dimType = column.type;
    // Disable nominal channels for quantitative fields
    if (channelTypes.includes('nominal') && channelTypes.length === 1 && dimType != 'nominal') {
      message = 'The channel ' + channel.name + ' doesn\'t support quantitative data.';
      return {'disable': true, 'message': message};
    }
    // Disable quantitative channels for nominal fields
    if (!channelTypes.includes('nominal') && dimType === 'nominal') {
      message = 'The channel ' + channel.name + ' doesn\'t support nominal data.';
      return {'disable': true, 'message': message};
    }
    // Disable small range quantitative channels for large quantitative fields
    if (channelTypes.includes('quantitative')) {
      if (column.key === 'mantissa' && (ch === 'row' || ch === 'column')) {
        message = 'The channel ' + channel.name + ' is not suitable for the mantissa';
        return {'disable': true, 'message': message};
      }
      // Small range channels are not suitable for several orders of magnitude
      if ((column.type == 'quantitative' && typeof column.range === 'bigint') && channel.range !== 'large') {
        message = 'The channel ' + channel.name + ' doesn\'t support large value ranges.';
        return {'disable': true, 'message': message};
      }
      // Small range channels are not suitable for the mantissa when it has more than 1 digit (>10).
      if (column.key === 'mantissa' && digitsMantissa > 1 && channel.range === 'small') {
        message = 'The channel ' + channel.name + ' is not suitable for the mantissa when it is encoded with more than 1 digit.';
        return {'disable': true, 'message': message};
      }
    }
    // constrainsts for line marks
    if (mark === 'line' && (ch === 'shape' || ch === 'orientation' || ch === 'area' || ch === 'angle' || ch === 'curvature')) {
      message = 'The channel ' + channel.name + ' is not suitable for line marks.';
      return {'disable': true, 'message': message};
    }
    // constrainsts for area marks
    if (mark === 'area-line' && (channel.category === 'Spatial size' || channel.category === 'Countable marks')) {
      message = 'The channel ' + channel.name + ' is not suitable for area-line marks.';
      return {'disable': true, 'message': message};
    }
  } else {
    console.log('This channel doesn\'t exist in the table.');
  }
  return {'disable': disable, 'message': message};
};


// Find and return the type of a scale (divar or band) based on data type (nominal or quantitative) of dimension
export const returnScaleType = (dim, selectedColumns) => {
  let type = '';
  type = returnDataType(dim, selectedColumns) === 'nominal' ? 'band' : 'divar';
  return type;
};

export const getColor = (channel) => {
  if (channel.colorIntensity) {
    return colorPalettes['colorIntensity'];
  } else if (channel.colorHue) {
    return colorPalettes['colorHue'];
  } else {
    return 'black';
  }
};

export const ifNoValue = (value) => {
  return (value === undefined || value === null || value ==='');
};

export const checkedChannel = (value) => {
  return !ifNoValue(value);// (value === null || value ==='');
};


// Find and return the domain of a dimension based on data values
export const returnDomain = (dim, digits, data) => {
  let domain = [];
  const values = data.map((item) => item[dim]);
  const low = Math.min(...values);
  const max = Math.max(...values);

  if (dim === 'exponent') {
    const exponentArray = [];
    // change domain based on digits
    for (let i = low - 1; i <= max + digits; i+= digits) {
      exponentArray.push(i);
    }
    domain = [Math.min(...exponentArray), Math.max(...exponentArray)];
  } else {
    domain = [0, 10 ** digits];
  }

  return domain;
};

// Find and return the domain of a dimension based on data values
export const returnDomainValuesBandScale = (dim, data) => {
  let domain = [];
  const values = data.map((item) => item[dim]);
  if (dim === 'exponent') {
    const exponentArray = [];
    // change domain based on digits
    values.map((v) => exponentArray.push(v));
    domain = [...exponentArray].sort((a, b) => a - b); ;
  } else {
    const mantissaArray = [];
    values.map((v) => mantissaArray.push(Math.floor(v)));
    domain = [...mantissaArray].sort((a, b) => a - b); ;
  }

  return domain;
};


export const returnMaxDomain = (dim, data, digits) => {
  const values = data.map((item) => item[dim]);
  const max = Math.max(...values);
  if (dim === 'mantissa') {
    return 10**digits;
  } else {
    return max;
  }
};

export const returnMinDomain = (dim, data) => {
  const values = data.map((item) => item[dim]);
  const min = Math.min(...values) - 1; // for presentation purposes we have extended the domain one up and down
  if (dim === 'mantissa') {
    return 0;
  } else {
    return min;
  }
};

export const returnWidth = (value, dim, data) => {

};


