
/* eslint-disable */
import * as utils from './utils.js';

const availableDatasets = [
    // {dataset: 'youtubeMetricsDates', attribute: 'Date', type:'ordinal', range:18},
    // {dataset: 'youtubeMetricsQuantitative', attribute: 'LikesPerComments', type:'quantitative', range:18},
    {dataset: 'youtubeMetricsNominal',  attribute: 'Category', type:'nominal', range:18},
  ];

const marks = [
"point", 
// "line", 
// "area-line"
];

const visualVariables = Object.entries(utils.channels);

const omvAttributes = ["exponent", "mantissa"]

const channelsAvailableForBothMantissaAndExponent = ["positionX", "positionY"]



const combinationsForPlot = [
    // {
    //     data: ...
    //     selectedColumns: ...,
    //     mark: ...,
    //     visualVariables: ...,
    // }
] 

const assignedVisualVariables = {
    positionX: null,
    positionY: null,
    column: null,
    row: null,
    length: null,
    area: null,
    colorIntensity: null,
    colorHue: null,
    shape: null,
  }

const possibleCombinations = [
    {
        // data: ...
        // mark: ...,
        // channels: ...,
    }
] 


export const createCombinations = () => {
    // For each attribute type: ordinal, quantitative and nominal
    // In the current solution each attribute type is corresponded to one dataset, so for each dataset (d)
    for (const dataset of availableDatasets) {
        // For each mark (m) type: point, line, area
        for (const mark of marks) {
            // For each visual variable (v)
            for (const visVar of visualVariables) {
                 // Check for constraints related to the attribute type and the mark type
                 // if no constraints
                 if(noConstraints(visVar, dataset, mark, assignedVisualVariables)) {
                    // apply visual variable to attribute, by updating assignedVisualVariables
                    assignedVisualVariables[visVar[0]] = dataset.attribute;
                        // create a list availableChannels with the visual variables that are null
                        const availableChannelsExponent = Object.entries(assignedVisualVariables).filter(([, value]) => value === null).map(([key]) => key);
                        // for each visual variable in available channels for exponent
                        for (const chanExp of availableChannelsExponent) {
                            const channelExpObj = Object.entries(utils.channels).find(([key, value]) => key === chanExp);
                            // check for possible constraints
                            if(assignedVisualVariables[chanExp] === null && noConstraints(channelExpObj, {attribute:"exponent", type:"quantitative"}, mark, assignedVisualVariables)){
                                // if there are no constraints
                                // assign omv attribute to visual variable, by updating assignedVisualVariables
                                assignedVisualVariables[chanExp] = "exponent";
                                // create a list with the channels that are available for mantissa
                                let availableChannelsMantissa;
                                // if exponent was assigned to a variable that can include both mantissa and exponnet
                                if(channelsAvailableForBothMantissaAndExponent.includes(chanExp)){
                                    // available channels for mantissa are the same as the available channels for the exponent
                                    availableChannelsMantissa = [...availableChannelsExponent]
                                } else {
                                    // else if exponent was assigned to a variable that can not take both, remove this variable from the available channels
                                    availableChannelsMantissa = availableChannelsExponent.filter(ch => ch !== chanExp);
                                }
                                // for each visual variable into the available variables for mantissa
                                for (let vmant = 0; vmant < availableChannelsMantissa.length; vmant++) {
                                    const chanMant = availableChannelsMantissa[vmant];
                                    const channelMantObj = Object.entries(utils.channels).find(([key, value]) => key === chanMant);
                                    // check for constraints
                                    if((assignedVisualVariables[chanMant] === null || channelsAvailableForBothMantissaAndExponent.includes(chanMant)) && noConstraints(channelMantObj, {attribute:"mantissa", type:"quantitative"}, mark, assignedVisualVariables)){
                                            // if no constraints assign mantissa into the variable
                                            assignedVisualVariables[chanMant] = assignedVisualVariables[chanMant] === null ? "mantissa" : assignedVisualVariables[chanMant] + "+mantissa";
                                            const combinationChannels = {...assignedVisualVariables} // it includes all the assigned variables for the data attribute, the exponent and the mantissa
                                            const combination = {data: dataset, mark: mark, channels: combinationChannels} // possible combination
                                            // add combination to possible combinations
                                            possibleCombinations.push(combination);
                                            // remove mantissa for the assigned visual variables to proceed with the next one
                                            assignedVisualVariables[chanMant] = assignedVisualVariables[chanMant] === "exponent+mantissa" ? 'exponent' : null;
                                    } // else continue to the next omv attribute
                                }
                            } // else if there are constraints procceed to the next availableChannel
                            // remove exponent for the assigned visual variables to proceed with the next one
                            assignedVisualVariables[chanExp] = null;
                        }
                 } // else if there are constraints, move to the next visual variable
                 //remove assignment for attribute to procceed to the next one
                 assignedVisualVariables[visVar[0]] = null;
            }
        }
    }
    console.log("Possible combinations: ", possibleCombinations)
    return possibleCombinations;
}


const noConstraints = (channel, dataAttribute, mark, assignedVisualVariables) => {
    const channelKey = channel[0];
    const channelObj = channel[1];
    const channelTypes = [...channelObj.type];
    const dataAttributeType = dataAttribute.type;

    // CONSTRAINTS BASED ON THE ATTRIBUTE TYPE
    // if data attribute is nominal
    if(dataAttributeType === 'nominal') {
        // if channel is not suitable for nominal data
        if(!channelTypes.includes('nominal')) return false;
        if(mark === 'area-line') return false;
    }

    // if data attribute is quantitative
    if(dataAttributeType === 'quantitative') {
        // if channel is not suitable for quantitative data
        if(!channelTypes.includes('quantitative')) return false;
    }

    // if data attribute is ordinal
    if(dataAttributeType === 'date') {
        // if channel is not suitable for quantitative data
        if(!channelTypes.includes('ordinal')) return false;
    }

    // CONSTRAINTS BASED ON THE MARK
    if(mark === 'point'){
        // CONSTRAINTS TO ENSURE THAT WE HAVE POSITION X AND Y
        // ensure that we have at least one Spatial position channel for every combination
        if(dataAttribute.attribute === 'mantissa'){
            // mantissa is assigned last, so if after the assignment either position x or y are null apply constraint
            if((assignedVisualVariables.positionX === null && assignedVisualVariables.positionY === null && assignedVisualVariables.row === null && assignedVisualVariables.column === null) && (channelKey.category !== 'Spatial size' )) return false;
        }
    }
    // if mark is line
    if(mark === 'line') {
        // if channel is not suitable for lines
        if(channel === 'area' || channelKey === 'shape') return false;
        // CONSTRAINTS TO ENSURE THAT WE HAVE POSITION X AND Y
        // ensure that we have at least one position X and position Y for every combination
        if(dataAttribute.attribute === 'exponent'){
            // exponnet is assigned first, so if after the assignment both position x and y are null apply constraint
            if((assignedVisualVariables.positionX === null && assignedVisualVariables.positionY === null) && (channelKey !== 'positionX' && channelKey !== 'positionY')) return false;
        }
        if(dataAttribute.attribute === 'mantissa'){
            // mantissa is assigned last, so if after the assignment either position x or y are null apply constraint
            if((assignedVisualVariables.positionX === null || assignedVisualVariables.positionY === null) && (channelKey !== 'positionX' && channelKey !== 'positionY')) return false;
        }
    } 
    // if mark is area
    if(mark === 'area-line'){
        // if channel is not suitable for areas
        if(channel.category === 'Spatial size' || channelKey === 'shape') return false;
        // CONSTRAINTS TO ENSURE THAT WE HAVE POSITION X AND Y
        // ensure that we have at least one position X and position Y for every combination
        if(dataAttribute.attribute === 'exponent'){
            // exponnet is assigned first, so if after the assignment both position x and y are null apply constraint
            if((assignedVisualVariables.positionX === null && assignedVisualVariables.positionY === null) && (channelKey !== 'positionX' && channelKey !== 'positionY')) return false;
        }
        if(dataAttribute.attribute === 'mantissa'){
            // mantissa is assigned last, so if after the assignment either position x or y are null apply constraint
            if((assignedVisualVariables.positionX === null || assignedVisualVariables.positionY === null) && (channelKey !== 'positionX' && channelKey !== 'positionY')) return false;
        }
    }
    return true;
}

export const combToPlot = () => {
    const combinations = createCombinations();
    const plotComb = [];
    combinations.forEach(c => {
        if(c.data !== undefined){
            plotComb.push({dataset: c.data, selectedColumns: ['mantissa', 'exponent', c.data.attribute], mark: c.mark, visualVariables: {...c.channels}})
        }
    })
    console.log(plotComb);
}