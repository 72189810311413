/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import * as Plot from '@observablehq/plot';
import {returnDataType, checkedChannel, getColor, returnDomainValuesBandScale} from '../utils.js';
import {unitData} from '../data.js';
import { MdOutlineFileDownload } from "react-icons/md";
import { MdContentCopy } from "react-icons/md";

const PlotComponent = ({data, visualVariables, digits, mark, selectedColumns}) => {
  const [error, setError] = useState(Error());
  // Ref to hold the SVG container
  const chartContainer = useRef(null);
  const plotCodeContainer = useRef(null);
  // style variables
  const colorFill = '#d6d8e7';
  const colorStroke = '#1c1e53';
  // dimensions
  const width = 800;
  const marginLeft = 100;
  const marginRight = 50;
  const marginTop = 20;
  const marginBottom = 50;
  const height = 450;
  const chartWidth = width - marginLeft - marginRight;
  const defaultPosition = 100;
  const color = !checkedChannel(visualVariables.colorIntensity) ? (!checkedChannel(visualVariables.colorHue) ? colorFill: visualVariables.colorHue) : visualVariables.colorIntensity;
  const typePositionX = visualVariables.positionX === 'exponent+mantissa' ? 'quantitative' : returnDataType(visualVariables.positionX, selectedColumns);
  const typePositionY = visualVariables.positionY === 'exponent+mantissa' ? 'quantitative' : returnDataType(visualVariables.positionY, selectedColumns);
  // Flags to control the scales for quantitative data and transform to band scale if needed
  const bothXYQuantitative = typePositionX === 'quantitative' && typePositionY === 'quantitative';
  const dateData = typePositionX === 'date' || typePositionY === 'date';
  const xyBetweenMantExp = [visualVariables.positionX, visualVariables.positionY].includes('mantissa') && [visualVariables.positionX, visualVariables.positionY].includes('exponent');
  const exponents = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const eplusmTicks = [0.45, 1.45, 2.45, 3.45, 4.45, 5.45, 6.45, 7.45, 8.45, 9.45, 10.45, 11.45];
  const defaultMaxDomain = 100; //for plots without position
  const defaultMaxDomainFacet = 20; //for plots without position
  const oneDayInMilliseconds = 1 * 24 * 60 * 60 * 1000;

  useEffect(() => {
    try {
      // Function to update the chart when data or visual variables or marks change
      if (data === undefined || visualVariables === undefined){
        colsole.log("data undefined")
        return;
      } 
      let chart = updateChart();

      function stringify(obj) {
        return JSON.stringify(obj, function (k, v) {
          if (typeof v === 'bigint') {
            return { __type:'bigint', value:v.toString() }
          }
          if (typeof v === 'function') {
            //console.warn(`cannot stringify function ${k}: `+v.toString())
          }
          return v
        }, 2);
      }

      function parse(json) {
        function isObject(o) {
          return typeof o === 'object' &&
            !Array.isArray(o) &&
            o !== null
        }
        return JSON.parse(json, (_, v) => {
          if (isObject(v) && '__type' in v && v.__type === 'bigint') {
            return BigInt(v.value);
          }
          return v;
        });
      }

      // //console.clear()
      let config = null;//localStorage.getItem('config');
      if (!config) {
        config = stringify(chart);
        localStorage.setItem('config', config);
      }

      const _marks = chart.marks
      const _facet = chart.facet

      // //console.log(config)
      const origChart = chart;
      chart = parse(config);
      console.log(chart)

      function plotCode() {
          const codeConfig = structuredClone(chart)
        delete codeConfig.facet
        codeConfig.marks = codeConfig.text_marks
        delete codeConfig.text_marks

        const codeConfigTxt = stringify(codeConfig)
        // Plot code for marks, without escaping double quotes and remove the string around plot call
          .replaceAll('\\"', '"')
          .replace(/"Plot\.(.*)\((.*?)\)"/g, 'Plot.$1($2)')
          // pretty simple numbers arrays as inline
          // .replace(/ *\n *(\d*,?) *\n */g, '$1')
          // .replace(/ *\n *\]/g, ']')
        //console.log(`Plot.plot(${codeConfigTxt})`)
        //console.log(stringify(chart.marks.find(d => d.data).data))
        // https://observablehq.com/d/e2cbb3687210c434

        return `Plot.plot(${codeConfigTxt})`
      }
      const plot_code = plotCode()

      // function getPlot() { return Plot }
      // chart.marks = chart.text_marks.map(txt => eval('const Plot = getPlot();'+txt));

      // TODO HERE
      chart.x.tickFormat = origChart.x.tickFormat
      chart.y.tickFormat = origChart.y.tickFormat


      //chart.marks = _marks
      //chart.facet = _facet

      //console.log(origChart)
      const origPlot = Plot.plot(origChart);

      let chart1 = updateChart();
      const plot = Plot.plot(chart1);

      plotCodeContainer.current.textContent = plot_code;

      // //console.clear()
      //console.log('Plot equals',origPlot.isEqualNode(plot));

      plot.setAttribute('role', 'main');
      chartContainer.current.append(plot);
      // eslint-disable-next-line consistent-return
      return () => plot.remove(); // remove the previous chart and redraw
    } catch (e) {
      setError(e);
      console.error(e);
    }
  }, [data, visualVariables, mark]);

  // Function to update the Plot chart
  const updateChart = () => {
    const scales = drawScales(visualVariables.positionX, visualVariables.positionY);
  
    // Initialize chart with base configuration and directly include conditional properties
    const chart = {
      width,
      height,
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      grid: true,
      symbol: { legend: checkedChannel(visualVariables.shape) },
      x: scales.x,
      y: scales.y,
      r: drawRadius(visualVariables.area),
      facet: {
        data,
        y: visualVariables.row,
        x: visualVariables.column,
      },
      marks: drawMarks(),
      text_marks: drawMarks(true),
      // Include fy and fx configurations conditionally
      ...(checkedChannel(visualVariables.row) && {
        fy: {
          tickFormat: (e) => formatScale(e, visualVariables.row),
          reverse: true,
        }
      }),
      ...(checkedChannel(visualVariables.column) && {
        fx: {
          tickFormat:(e) => formatScale(e, visualVariables.column),
        }
      }),
      // Conditionally include color configuration
      ...(visualVariables.colorIntensity || visualVariables.colorHue) && {
        color: {
          scheme: getColor(visualVariables),
          legend: true, 
        }
      },
    };
  
    return chart;
  };



  const drawScales = (positionX, positionY) => {
    // initialize values for both x and y
    const scaleBase = {
      tickPadding: 5,
      tickSize: 0,
      grid: true,
      nice: true, 
      // round: true,
    };
    // for x
    const x = {
      ...scaleBase,
      label: positionX,
      ticks: tickValues(positionX),
      tickFormat: (e) => formatScale(e, positionX),
      ...getDomain(positionX, bothXYQuantitative, xyBetweenMantExp, false, 'x'),
    };
    // for y
    const y = {
      ...scaleBase,
      label: positionY,
      ticks: positionY === 'exponent+mantissa' ? 10 : tickValues(positionY),
      tickFormat: (e) => formatScale(e, positionY),
      ...getDomain(positionY, bothXYQuantitative, xyBetweenMantExp, true, 'y'),
    };

    // To do: implement scales for dates
    return {x: x, y: y};
  };

  const formatScale = (e, position) => {
    if(position === 'exponent'){
      return e > 0 ? 10 ** e : null;
    }
    else if(position === 'mantissa'){
      return e !== 0 ? e : null;
    } else if (position === 'exponent+mantissa'){
      const exp = Math.trunc(e);
      const mant = e - exp;
      return (mant !== 0) ? null : 10 ** exp;
    } else if(position === 'Date'){
      if(visualVariables.column === 'Date' || visualVariables.row === 'Date'){
        return data.some(d => isEqualDate(d['Date'],e)) && !isEqualDate(e,"2018-01-29T12:00:00.000Z") ? e.split('T')[0]  : '';
      } else {
        return data.some(d => isEqualDate(d['Date'],e)) && !isEqualDate(e,"2018-01-29T12:00:00.000Z") ? Plot.formatIsoDate(new Date(e))  : '';
      }
    } else {
      return e;
    }
  }

  const scaleExpPlusMant = (value) => {
    const exp = Math.trunc(value);
    const mant = (10/9 * (value - exp));
    const scale = (mant != 0) ? mant * 10 ** (exp + 1) : 10 ** exp;
    return Math.round(scale * 100) / 100;  // correcting for rounding errors
  }

  const drawMarks = (intext) => {
    const marks = [];
    // Axis style for nominal data
    if (returnDataType(visualVariables.positionX, selectedColumns) === 'nominal') {
      marks.push(
        intext ?
          `Plot.axisX({lineWidth: 4, marginBottom: 100})`
          : Plot.axisX({lineWidth: 4, marginBottom: 100}),
      );
    }
    if (returnDataType(visualVariables.positionY, selectedColumns) === 'nominal') {
      marks.push(
        intext ?
          `Plot.axisY({lineWidth: 10, marginLeft: 100})`
          :
          Plot.axisY({lineWidth: 10, marginLeft: 100}),
      );
    }
    if (visualVariables.positionY === "exponent+mantissa"){
      marks.push(
        Plot.ruleY(exponents, { y: e => e}), // Dark lines to seperate each exponent
        Plot.ruleY(eplusmTicks , {opacity: 0.4}),
        Plot.textY(eplusmTicks, { y: d=> d, dx:-(chartWidth/2)-5, opacity: 0.5, text: d=> scaleExpPlusMant(d), textAnchor:"end"}),
      )
    }

    
    // Add frame for faceting
    if (visualVariables.row || visualVariables.column) {
      marks.push(
        intext ? `Plot.frame()` : Plot.frame());
    }
    // if mark is point
    if (mark === 'point') {
      const pointMarks = drawPoints(marks, intext);
      return pointMarks;
    }
    // if mark is encoded as line
    if (mark === 'line') {
      const lineMarks = drawMarkLine(marks, 'minDomain', 'position');
      return lineMarks;
    }
    // if mark is encoded as area-line
    if (mark === 'area-line') {
      const areaMarks = drawAreas(marks);
      return areaMarks;
    }
    return null;
  };

  const drawPoints = (marksArray, intext) => {
    const chart = {
      fill: color,
      stroke: colorStroke,
      tip: true,
      dx: visualVariables.positionX === 'Date' ? -32 : 0,
      ...(visualVariables.row && { fy: visualVariables.row }),
      ...(visualVariables.column && { fx: visualVariables.column }),
    };

  
    // Handle specific visual variable conditions
    if (visualVariables.length) {
      if(!checkedChannel(visualVariables.positionX) || !checkedChannel(visualVariables.positionY)){
        const chartConfig = {
          ...chart,
          stroke: color,
          strokeWidth: d => getRuleStroke(d)
        };
      
        if (checkedChannel(visualVariables.positionY)) {
          marksArray.push(
            Plot.ruleY(dateData ? data.filter(d=> d["exponent"]>0): data, { x1: d => checkForJitter(visualVariables.positionY, d, 'x') - d[visualVariables.length]/2, x2:d =>checkForJitter(visualVariables.positionY, d, 'x') + d[visualVariables.length]/2, y: visualVariables.positionY, strokeWidth: d => getRuleStroke(d) + 2, ...chart }),
            Plot.ruleY(dateData ? data.filter(d=> d["exponent"]>0): data, { x1: d => checkForJitter(visualVariables.positionY, d, 'x') - d[visualVariables.length]/2, x2:d =>checkForJitter(visualVariables.positionY, d, 'x') + d[visualVariables.length]/2, y: visualVariables.positionY, ...chartConfig }),
          );
        } else if (checkedChannel(visualVariables.positionX)) {
          marksArray.push(
            Plot.ruleX(dateData ? data.filter(d=> d["exponent"]>0): data, { x: visualVariables.positionX, y1: d => checkForJitter(visualVariables.positionX, d, 'y') - d[visualVariables.length]/2, y2: d =>checkForJitter(visualVariables.positionX, d, 'y') + d[visualVariables.length]/2, strokeWidth: d => getRuleStroke(d) + 2, ...chart }),
            Plot.ruleX(dateData ? data.filter(d=> d["exponent"]>0): data, { x: visualVariables.positionX, y1: d => checkForJitter(visualVariables.positionX, d, 'y') - d[visualVariables.length]/2, y2: d =>checkForJitter(visualVariables.positionX, d, 'y') + d[visualVariables.length]/2, ...chartConfig }),
          );
        } else if (checkedChannel(visualVariables.column)) {
          marksArray.push(
            Plot.ruleX(dateData ? data.filter(d=> d["exponent"]>0): data, { x: d=> checkForJitter(visualVariables.column, d, 'x'), y1: d => getDefaultMaxDomain('y')/2 - d[visualVariables.length]/2, y2: d =>getDefaultMaxDomain('y')/2 + d[visualVariables.length]/2, strokeWidth: d => getRuleStroke(d) + 2, ...chart }),
            Plot.ruleX(dateData ? data.filter(d=> d["exponent"]>0): data, { x: d=> checkForJitter(visualVariables.column, d, 'x'), y1: d => getDefaultMaxDomain('y')/2 - d[visualVariables.length]/2, y2: d =>getDefaultMaxDomain('y')/2 + d[visualVariables.length]/2, ...chartConfig }),
          );
        } else if (checkedChannel(visualVariables.row)) {
          marksArray.push(
            Plot.ruleY(dateData ? data.filter(d=> d["exponent"]>0): data, { x1: d => getDefaultMaxDomain('x')/2 - d[visualVariables.length]/2, x2: d =>getDefaultMaxDomain('x')/2 + d[visualVariables.length]/2, y: d=> checkForJitter(visualVariables.row, d, 'y'), strokeWidth: d => getRuleStroke(d) + 2, ...chart }),
            Plot.ruleY(dateData ? data.filter(d=> d["exponent"]>0): data, { x1: d => getDefaultMaxDomain('x')/2 - d[visualVariables.length]/2, x2: d =>getDefaultMaxDomain('x')/2 + d[visualVariables.length]/2, y: d=> checkForJitter(visualVariables.row, d, 'y'), ...chartConfig }),
          );
        }
        return marksArray;

      } else {
        if(typePositionX === 'nominal' || typePositionY === 'nominal' || typePositionX === 'ordinal' || typePositionY === 'ordinal'){
          return [...drawMarkLine(marksArray, 'position', 'length')];
        } else {
          const chartConfig = {
            ...chart,
            stroke: color,
            strokeWidth: d => getRuleStroke(d)
          };
          marksArray.push(
            Plot.ruleX(dateData ? data.filter(d=> d["exponent"]>0): data, { x: visualVariables.positionX, y1: visualVariables.positionY, y2: d=>drawLength(d, visualVariables.positionY), strokeWidth: d => getRuleStroke(d) + 2, ...chart }),
            Plot.ruleX(dateData ? data.filter(d=> d["exponent"]>0): data, { x: visualVariables.positionX, y1: visualVariables.positionY, y2: d=>drawLength(d, visualVariables.positionY), ...chartConfig }),
          );
          return marksArray;
        }
      }
      
    } else {
      const baseImageConfig = { ...chart, width: 40, height: 40 };
      const visualVariableConfig = getVisualVariableConfig(visualVariables, baseImageConfig);
      if (visualVariableConfig) {
        marksArray.push(Plot.image(data, visualVariableConfig));
      } else {
        // Default to dot if no specific visual variable config is found
        const defaultConfig = {
          r: !checkedChannel(visualVariables.area) ? 5 : visualVariables.area,
          symbol: visualVariables.shape || 'circle',
          dx: visualVariables.positionX === 'Date' ? -32 : 0,
        };
        if(!checkedChannel(visualVariables.positionX)){
          marksArray.push(Plot.dot(dateData ? data.filter(d=> d["exponent"]>0): data, Plot.dodgeX("middle",{ y: checkedChannel(visualVariables.positionY) ? visualVariables.positionY : getDefaultMaxDomain('y')/2, ...chart, ...defaultConfig })));
        } else if(!checkedChannel(visualVariables.positionY)){
          marksArray.push(Plot.dot(dateData ? data.filter(d=> d["exponent"]>0): data, Plot.dodgeY("middle",{ x: checkedChannel(visualVariables.positionX) ? visualVariables.positionX : getDefaultMaxDomain('x')/2,
            ...chart, ...defaultConfig })));
        } else {
          marksArray.push(Plot.dot(dateData ? data.filter(d=> d["exponent"]>0): data, { x: checkedChannel(visualVariables.positionX) ? visualVariables.positionX : getDefaultMaxDomain('x')/2,
            y: checkedChannel(visualVariables.positionY) ? visualVariables.positionY : getDefaultMaxDomain('y')/2, ...chart, ...defaultConfig }));
        }
        
      }
    }
  
    return marksArray;
  };

  const checkForJitter = (column, d, axis) => {
    //find how many rows in the dataset contains the same value
    const sameValues = data.filter(v => v[column] === d[column]);
    if(sameValues.length > 1){
      const index = sameValues.indexOf(d);
      const position = (index + 1) * getDefaultMaxDomain(axis)/(sameValues.length*2)
      return position;
    } else {
      //if the value is unique return the middle of the plot as position
      return getDefaultMaxDomain(axis)/2;
    }
  }
  

  const drawMarkLine = (marksArray, start, end) => {
    const marks = [...marksArray];
    // if nominal
    if (typePositionX === 'nominal' || typePositionY === 'nominal' || typePositionX === 'ordinal' || typePositionY === 'ordinal') {
      return linesNominal(marks, start, end);
    }
    // if date
    if (typePositionX === 'date' || typePositionY === 'date') {
      return linesDates(marks, start, end);
    }
    // if both data are quantitative draw rectangles instead of bars
    if (bothXYQuantitative && !xyBetweenMantExp) {
      return linesQuantitative(marks);
    }
  };

  const linesNominal = (marks, start, end) => {
    const baseChartConfig = {
      fill: color,
      stroke: colorStroke,
      channels: {value: 'value'},
      // tip: true,
      insetRight: 30,
      insetLeft: 30,
      ...(visualVariables.row && { fy: visualVariables.row, insetLeft: 0, insetRight: 0 }),
      ...(visualVariables.column && { fx: visualVariables.column, insetLeft: 0, insetRight: 0 }),
    };
    // Handling for nominal dimensions with bars, lines, or units
    if (isBarY()) {
      baseChartConfig.x = visualVariables.positionX;
      baseChartConfig.y1 = start === 'minDomain' ? 0 : visualVariables.positionY;
      baseChartConfig.inset = 0.1;
      if (mark === 'line' && checkedChannel(visualVariables.length)) {
        return drawWidth(true, baseChartConfig, marks);
      } else {
        return drawBarsOrUnits(baseChartConfig, 'Y', marks, end);
      }
    } else if (isBarX()) {
      baseChartConfig.y = visualVariables.positionY;
      baseChartConfig.x1 = start === 'minDomain' ? 0 : visualVariables.positionX;
      if (mark === 'line' && checkedChannel(visualVariables.length)) {
        return drawWidth(false, baseChartConfig, marks);
      } else {
        return drawBarsOrUnits(baseChartConfig, 'X', marks, end);
      }
    } else {
      console.log('No barX or barY detected. Please check your visual variables.');
    }
    return marks;
  };

  const drawBarsOrUnits = (chartConfig, axis, marksArray, end) => {
    if (checkedChannel(visualVariables.units)) {
    //   const units = unitData(data, visualVariables.units);
    //   const unitChartConfig = {
    //     ...chartConfig,
    //     [axis === 'Y' ? 'y' : 'x']: 1,
    //     fill: chartConfig.fill,
    //     stroke: colorStroke,
    //     height: 40,
    //     preserveAspectRatio: 'xMidYMin slice',
    //     ...(visualVariables.row && { fy: visualVariables.row }),
    //     ...(visualVariables.column && { fx: visualVariables.column }),
    //   };
      // Update unitChartConfig with visual variable config if applicable
      // const visualVarConfig = getVisualVariableConfig(visualVariables, unitChartConfig);
      // if (visualVarConfig) {
      //   marksArray.push(Plot.image(units, Plot.stackY2(visualVarConfig)));
      // } else {
      //   marksArray.push(Plot.dot(units, Plot.stackY2(unitChartConfig)));
      // }
    } else {
      chartConfig[axis === 'Y' ? 'y2' : 'x2'] = end === 'position' ? visualVariables[`position${axis}`] : (d) => drawLength(d, visualVariables[`position${axis}`]);
      marksArray.push(axis === 'Y' ? Plot.barY(data, chartConfig) : Plot.barX(data, chartConfig));
    }
    return marksArray;
  }

  const linesDates = (arrayMarks, start, end) => {
    const marks = [...arrayMarks];
    let plotConfig = {
      fill: color,
      stroke: colorStroke,
      interval: 'day', // Assuming 'day' is a common interval for both cases
    };

     // Determine colorReducer based on condition
     const colorReducer = plotConfig.fill === colorFill ? {} : { fill: 'max' };
  
    // Determine if the position is date type and configure the plot accordingly
    if (returnDataType(visualVariables.positionX, selectedColumns) === 'date') {

      Object.assign(plotConfig, {
        x: (d) => d[visualVariables.positionX] ,
        dx:-32,
        y1: start === 'minDomain' ? 0 : visualVariables.positionY,
        y2: end === 'position' ? visualVariables.positionY : d => drawLength(d, visualVariables.positionY),
        // strokeWidth: d => checkedChannel(visualVariables.length) && mark === 'point' ? 
        //   (visualVariables.length === 'mantissa' ? d[visualVariables.length] / (10 ** (digits - 1)) : d[visualVariables.length]) : 4,
      });
      marks.push(
        // Plot.axisX(data, {dx: 10}),
        Plot.rectY(data, Plot.binX({y2: "max", filter: null, ...colorReducer}, plotConfig)), 
        // Plot.text(data, {text:visualVariables.positionX, x:visualVariables.positionX, y:0}),
        Plot.ruleY([0])
      );

    } else if (returnDataType(visualVariables.positionY, selectedColumns) === 'date') {
      Object.assign(plotConfig, {
        y: visualVariables.positionY,
        x1: start === 'minDomain' ? 0 : visualVariables.positionX,
        x2: end === 'position' ? visualVariables.positionX : d => drawLength(d, visualVariables.positionX),
      });
      marks.push(Plot.rectX(data, plotConfig), Plot.ruleX([0]));
    }
  
    return marks;
  };

  const linesQuantitative = (arrayMarks) => {
    const marks = [...arrayMarks];
    // Common chart configuration
    const chartConfig = {
      stroke: color,
      strokeWidth: d => checkedChannel(visualVariables.length) 
        ? (visualVariables.length === 'mantissa' ? d[visualVariables.length] / (10 ** (digits - 1)) * 2 : d[visualVariables.length]) * 2
        : 5
    };
  
    // Determine the direction based on which variable is not 'mantissa' or 'exponent'
    if (['mantissa', 'exponent', 'exponent+mantissa'].includes(visualVariables.positionX)) {
      marks.push(
        Plot.ruleY(data, { x1: 0, x2: visualVariables.positionX, y: visualVariables.positionY, ...chartConfig }),
        Plot.ruleX([0])
      );
    } else if (['mantissa', 'exponent', 'exponent+mantissa'].includes(visualVariables.positionY)) {
      marks.push(
        Plot.ruleX(data, { x: visualVariables.positionX, y1: 0, y2: visualVariables.positionY, ...chartConfig }),
        Plot.ruleY([0])
      );
    }
  
    return marks;
  };

  const drawAreas = (marks) => {
    // Check for nominal data first to avoid unnecessary processing
    if (typePositionX === 'nominal' || typePositionY === 'nominal' || typePositionX === 'ordinal' || typePositionY === 'ordinal') {
      setError('Area-line charts are not suitable for nominal or ordinal data');
      return;
    }
    const chart = {
      x: d => new Date(d[visualVariables.positionX]).getTime() - oneDayInMilliseconds,
      y: visualVariables.positionY,
      fill: color,
      stroke: colorStroke,
      // tip: true,
      z: null,
      ...(visualVariables.row && { fy: visualVariables.row }),
      ...(visualVariables.column && { fx: visualVariables.column }),
      ...(dateData && { interval: 'day' }),
    };

     // Determine colorReducer based on condition
     const colorReducer = chart.fill === colorFill ? {} : { fill: 'max' };

     const addMarksDate = (axis, dataFunc, binFunc) => {
      if (['date', 'quantitative'].includes(eval(`typePosition${axis}`)) && !['mantissa', 'exponent', 'exponent+mantissa'].includes(visualVariables[`position${axis}`])) {
        const marksDate = [
          dataFunc(data,
            {
              ...chart,
              sort: visualVariables[`position${axis}`],
            },
          ),
        ];
        marks.push(marksDate);
      }
    };
  
    // Process marks for X and Y positions
    addMarksDate('X', Plot.areaY);
    addMarksDate('Y', Plot.areaX);
    
  
    return marks;
  };

  // for different countable marks
  const getVisualVariableConfig = (visualVariables, baseConfig) => {
    // if (visualVariables.countSymbol) {
    //   return {
    //     ...baseConfig,
    //     src: visualVariables.countSymbol === 'mantissa' ? 'dominoMantissa' : 'dominoExponent',
    //   };
    // } else if (visualVariables.countShape) {
    //   return {
    //     ...baseConfig,
    //     src: visualVariables.countShape === 'mantissa' ? 'starMantissa' : 'starExponent',
    //   };
    // } else if (visualVariables.countTexture) {
    //   return {
    //     ...baseConfig,
    //     src: visualVariables.countTexture === 'mantissa' ? 'textureMantissa' : 'textureExponent',
    //   };
    // } else {
      return null; // No configuration needed for the provided visual variables
    // }
  };


  const isBarY = () => {
    if (returnDataType(visualVariables.positionX, selectedColumns) === 'nominal'|| returnDataType(visualVariables.positionX, selectedColumns) === 'ordinal') {
      return true;
    } else if (returnDataType(visualVariables.positionY, selectedColumns) === 'nominal' || returnDataType(visualVariables.positionY, selectedColumns) === 'ordinal') {
      return false;
    } else if (visualVariables.positionX === 'exponent' || (visualVariables.positionY !== 'exponent' && visualVariables.positionX === 'mantissa')) {
      return true;
    } else {
      return false;
    }
  };

  const isBarX = () => {
    if (returnDataType(visualVariables.positionY, selectedColumns) === 'nominal' || returnDataType(visualVariables.positionY, selectedColumns) === 'ordinal') {
      return true;
    } else if (visualVariables.positionY === 'exponent' || (visualVariables.positionX !== 'exponent' && visualVariables.positionY === 'mantissa')) {
      return true;
    } else {
      return false;
    }
  };

  const drawRadius = (area) => {
    if (!checkedChannel(area)) return {range: [10, 10]};
    return {domain: [d3.min(data, (d) => d[visualVariables.area]), d3.max(data, (d) => d[visualVariables.area])], range: [4, 20]};
  };


  const drawLength = (d, position) => {
    if (position === 'exponent') {
      if (visualVariables.length !== 'mantissa') {
        return d[position] + (d[visualVariables.length]/d3.max(data, (v) => v[visualVariables.length]));
      } else {
        return d[position] + d[visualVariables.length]/10**digits;
      } 
    } else if (position === 'mantissa') {
      if (visualVariables.length !== 'mantissa') {
        return d[position] + (d[visualVariables.length]/d3.max(data, (v) => v[visualVariables.length]) * 10**(digits-1));
      } else {
        return d[position] + d[visualVariables.length]/10**digits;
      }
    } else {
      console.log('Not implemented for position different than mantissa and exponent');
      return 0;
    }
  };

  // dynamic manipulation of inset id not supported by Plot
  const setInset = (d) => {
    const positionXValues = data.map((item) => item[visualVariables.positionX]);
    // aproximate Width per Band, in practice is smaller as it doesn't take into account the axis and the padding
    const widthBand = positionXValues.length > 0 ? chartWidth / positionXValues.length : 0;
    const max = d3.max(data, (v) => v[visualVariables.length]);
    const min = d3.min(data, (v) => v[visualVariables.length]);
    const ratio = (max - d)/max; // ratio between d and max value of the domain
    // to better display width differences we compute the range of domain values
    const range = (max - min)/max;
    // range is between 0 and 1, the smaller the range the fewer different values that we visualize
    // we divide by 4, to change the analogy. For examlpe to make 2/4 into 2, 3/4 into 3 etc.
    const inset = widthBand/range/4*ratio;

    return inset;
  };

  // draw bars with different width
  const drawWidth = (barY, chart, marks) => {
    const widthBars = [...marks];
    // Extract unique values for the length domain
    const lengthDomain = Array.from(new Set(data.map((item) => item[visualVariables.length])));
    // for every value of lengthDomain create an other bar
    // we do this because we can not access data inside inset like we do with x, y, or fill
    // so we create different bars based on the dimension that is choosen for length
    lengthDomain.map((dimValue) => {
      const inset = setInset(dimValue);
      chart.opacity = (d) => d[visualVariables.length] === dimValue ? 1 : 0;
      if (barY) {
        chart.y2 = visualVariables.positionY;
        chart.insetLeft = inset;
        chart.insetRight = inset;
        widthBars.push(Plot.barY(data, chart));
      } else { // if bar is x
        chart.x2 = visualVariables.positionX;
        chart.insetTop = inset;
        chart.insetBottom = inset;
        widthBars.push(Plot.barX(data, chart));
      }
    });
    return widthBars;
  };

  const getDomain = (position, bothXYQuantitative, xyBetweenMantExp, reverseDiscrete, axis) => {
    if (returnDataType(position, selectedColumns) === 'quantitative') {
        if (position !== 'mantissa') {
          return {
            domain: [0, d3.max(data, (d) => d[position]) + 1],
          };
        } else {
          return {
            domain: [0, 10**digits],
          };
        } 
    } else if (position === 'exponent+mantissa') {
      return {
        domain: [0, d3.max(data, (d) => d[position])],
        type: 'linear'
      };
    } else if (returnDataType(position, selectedColumns) === 'date') {
      const minDate = new Date(data[0].Date);
      const maxDate = new Date(data[data.length - 1].Date);
      // increase the domain to include days

      // Create a new Date object with the new timestamp
      var newMinDate = new Date(minDate.getTime() - oneDayInMilliseconds);
      var newMaxDate = new Date(maxDate.getTime() + oneDayInMilliseconds);
      return {
        domain: [newMinDate, maxDate],
        ticks: 'day',
      };
    } else if (!checkedChannel(position)) {
      return {
        domain: [0, getDefaultMaxDomain(axis)], //to draw everything without position based on 100
        type: 'linear'
      };
    } else {
      return {};
    }
  };

  //To get domain for plots without position
  const getDefaultMaxDomain = (axis) => {
    if(visualVariables.positionY === null && visualVariables.positionX === null){
      if(checkedChannel(visualVariables.row)){
        if(axis === 'y'){
          return defaultMaxDomainFacet;
        } else {
          return defaultMaxDomain;
        }
      }
      if(checkedChannel(visualVariables.column)){
        if(axis === 'x'){
          return defaultMaxDomainFacet;
        } else {
          return defaultMaxDomain;
        }
      }
    } else if((visualVariables.positionY === null && checkedChannel(visualVariables.row)) || 
    (visualVariables.positionX === null && checkedChannel(visualVariables.column)) 
    ){
      return defaultMaxDomainFacet;
    } else {
      return defaultMaxDomain;
    }
  }

  const tickValues = (position) => {
    let array = [];
    if (position === 'mantissa') {
      for (let i = 0; i <= 10; i++) {
        array.push(i * 10**(digits-1));
      }
      return array;
    } else if (position === 'exponent') {
      for (let i = 0; i <= d3.max(data, (d) => d[position]) + 1; i++) {
        array.push(i);
      }
      
    } else if (position === 'exponent+mantissa') {
        for (let i = 0; i <= d3.max(data, (d) => d['exponent+mantissa']) + 1; i++) {
          array.push(i);
        }
       
    } else if(position === 'Date'){
      array = [new Date('22/01/2018'), new Date('23/01/2018'), new Date('24/01/2018'), new Date('25/01/2018'), new Date('26/01/2018'), new Date('27/01/2018'), new Date('28/01/2018')]
    } else if(position !== 'exponent+mantissa' && !checkedChannel(position)){
      return [];
    } else {
      return 20;
    }
    return array;
  };


  const isEqualDate = (d1, d2) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    return  date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
  }


  const getRuleStroke = (d) => {
    return checkedChannel(visualVariables.area) 
            ? d[visualVariables.area] * 4
            : 5
  }


  function copyCodeToClipboard() {
    navigator.clipboard.writeText(plotCodeContainer.current.value)
  }

  // TODO HERE
  function downloadText(text, name, type) {
    const blob = new Blob([text], { type: type });
    const a = document.createElement("a");
    a.setAttribute("download", name);
    a.href = window.URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(a.href);
    a.remove();
  }

  const download = {
    png(WIDTH = 1920, HEIGHT = 1080) { // TODO WIDTH doesn't work
      try {
        const img = document.createElement('img');
        img.setAttribute('width', WIDTH);
        img.setAttribute('height', HEIGHT);
        document.body.appendChild(img);

        const canvas = document.createElement('canvas');
        canvas.setAttribute('width', WIDTH);
        canvas.setAttribute('height', HEIGHT);
        document.body.appendChild(canvas);

        const svg = chartContainer.current.querySelector('svg')
        if (svg.parentElement.tagName === 'FIGURE') {
          for (const element of svg.parentElement.childNodes) {
            if (element === svg) continue;
            const el = element.cloneNode(true)
            if (el.tagName === 'div') {
              const foreignEl = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
              foreignEl.append(el);
              foreignEl.setAttribute('x', WIDTH - parseInt(el.getAttribute('width') - 25));
              foreignEl.setAttribute('y', parseInt(el.getAttribute('height') - 142));
              svg.append(foreignEl);
            }
            if (el.tagName === 'svg') {
              el.setAttribute('x', WIDTH - parseInt(el.getAttribute('width') - 25));
              svg.append(el);
            }
          }
        }

        const exportName = svg.closest('.plot')?.querySelector('.plotId')?.textContent || 'om_img_plot';
        const desc = svg.closest('.plot')?.querySelector('.desc')?.textContent;

        img.onload = function() {
          canvas.getContext('2d').fillStyle = 'white';
          canvas.getContext('2d').fillRect(0, 0, WIDTH, HEIGHT);
          canvas.getContext('2d').drawImage(img, 50, 50);

          if (desc) {
            canvas.getContext('2d').fillStyle = 'black';
            canvas.getContext('2d').font = '14px Arial';
            canvas.getContext('2d').fillText(desc, 10, HEIGHT - 50);
          }

          const downloadAnchorNode = document.createElement('a');
          downloadAnchorNode.setAttribute('href', canvas.toDataURL('image/png'));
          downloadAnchorNode.setAttribute('download', exportName + '.png');
          document.body.appendChild(downloadAnchorNode); // required for firefox
          downloadAnchorNode.click();
          downloadAnchorNode.remove();

          canvas.remove();
          img.remove();
        };
        img.src = 'data:image/svg+xml;base64,'+btoa(unescape(encodeURIComponent(new XMLSerializer().serializeToString(svg))));
      } catch (err) {
        console.error(err);
      }
    },
    svg() {
      downloadText(chartContainer.current.innerHTML, 'om_plot.svg', 'image/svg')
    },
    code() {
      downloadText(plotCodeContainer.current.value, 'om_plot.js', 'text/javascript')
    }
  }

  // Render the SVG container
  return <>
    <div  className="plot" ref={chartContainer}></div>
    <button className="primary" onClick={() => download.png()}><MdOutlineFileDownload />Download PNG</button>
    <button className="primary" onClick={() => download.svg()}><MdOutlineFileDownload />Download SVG</button>
    <button className="primary" onClick={() => download.code()}> <MdOutlineFileDownload />Download Plot Code</button>
    <button className="primary" onClick={() => copyCodeToClipboard()}><MdContentCopy /> Copy Plot Code</button>
    <textarea ref={plotCodeContainer} disabled={true}></textarea>
  </>;
};

export default PlotComponent;
