import {arrayIsPrimitive} from '@observablehq/stdlib';
import initDB from './duckDB.js';


// load data to DuckDB
// const youtubeMetrics = new URL('youtubeMetrics.parquet', import.meta.url).href;
// const youtubeMetricsDates = new URL('youtubeMetricsDates.parquet', import.meta.url).href;
// const youtubeMetricsQuantitative = new URL('youtubeMetricsQuantitative.parquet', import.meta.url).href;
// const youtubeMetricsNominal = new URL('youtubeMetricsNominal.parquet', import.meta.url).href;
const nominalSm = new URL('nominalSm.parquet', import.meta.url).href;
const quantitativeSm = new URL('quantitativeSm.parquet', import.meta.url).href;
const rankingSm = new URL('ranking.parquet', import.meta.url).href;
const datesSm = new URL('datesSm.parquet', import.meta.url).href;

// add them to an array
const availableDatasets = [
  // {'youtubeMetricsDates': youtubeMetricsDates},
  // {'youtubeMetricsQuantitative': youtubeMetricsQuantitative},
  // {'youtubeMetricsNominal': youtubeMetricsNominal},
  {'nominalSmall': nominalSm},
  {'quantitativeSmall': quantitativeSm},
  {'rankingSmall': rankingSm},
  {'datesSmall': datesSm},
];

// Setup and connect to the database
let conn_prom = null;
let db_instance = null;
const load_db = async (uploadedDatasets) => {
  if (!conn_prom) {
    // Initialize database if it hasn't been initialized yet.
    db_instance = await initDB();
    conn_prom = db_instance.connect();
  }

  const allDatasets = [...availableDatasets, ...uploadedDatasets];
  console.log('All Datasets: ', allDatasets);

  await Promise.all(
      allDatasets.map(async (dataset) => {
        await Promise.all(
            Object.entries(dataset).map(async ([key, value]) => {
              await db_instance.registerFileURL(key, value, 4, false);
              console.log(`Registered file: ${key} with URL: ${value}`);
            }),
        );
      }),
  );

  return conn_prom;
};

// Send query and await results from DuckDB
const get_query = async (q) => {
  const conn = await conn_prom;
  const results = await conn?.query(q);
  return results;
};


const get_results = async (q, uploadedDatasets) => {
  await load_db(uploadedDatasets); // Wait until DuckDB ready
  // Query the parquet file
  const table = await get_query(q);

  const table_arr = table?.toArray(); // list of objects, compatible with OJS

  return table_arr;
};

const resultsToArray = (resultsFromDuckDB) => {
  return resultsFromDuckDB.map((row) => {
    return {...row};
  });
};

// Method to handle dataset name
const getDatasetName = (dataset) => {
  if (dataset == 'youtubeMetrics') {
    return 'youtubeMetrics';
  } else {
    console.log('There is no dataset with this name.');
    return '';
  }
};


// ----------------------------------
// ------------QUERIES---------------
// ----------------------------------


const get_views_per_Category = `
  SELECT Category, sum(Views) as Views
  FROM parquet_scan('youtubeMetrics')
  GROUP BY Category
`;

const get_youtubeMetrics = `
  SELECT *
  FROM parquet_scan('youtubeMetrics')
`;

const getAllDataset = (dataset) => {
  return `
  SELECT *
  FROM parquet_scan('`+ dataset +`')
`;
};

const getOMVperSelectedColumnsQuery = (omv, selectedColumns, dataset) => {
  // Exclude 'mantissa' and 'exponent' keys from the selected columns
  const columnsInDataset = selectedColumns.filter((c) => c.key !== 'mantissa' && c.key !== 'exponent');
  // Create a query string from the remaining columns
  const queryString = columnsInDataset.map((column) => column.key).join(', ');
  console.log(queryString);
  return `
  SELECT `+ queryString +`, sum(`+ omv +`) as `+ omv +`
  FROM parquet_scan('`+ dataset +`')
  GROUP BY `+ queryString +`
`;
};


const dataDB = {
  async getViewsPerCategory(uploadedDatasets) {
    const results = await get_results(get_views_per_Category, uploadedDatasets);
    return resultsToArray(results);
  },
  async getAllFromDataset(datasetName, uploadedDatasets) {
    const results = await get_results(getAllDataset(datasetName), uploadedDatasets);
    return resultsToArray(results);
  },
  async getOMVperSelectedColumns(omv, selectedColumns, datasetName, uploadedDatasets) {
    const results = await get_results(getOMVperSelectedColumnsQuery(omv, selectedColumns, datasetName), uploadedDatasets);
    return resultsToArray(results);
  },
  getDatasetNames() {
    const datasetNameList = availableDatasets.map((dataset) => Object.keys(dataset)[0]);
    console.log(datasetNameList);
    return datasetNameList;
  },

  async uploadFiletoDB(uploadedDatasets) {
    console.log('there');
    try {
      await load_db(uploadedDatasets);
      console.log('Uploaded!');
    } catch (e) {
      console.error('Error uploading file: ', e);
    }
  },
};

export default dataDB;
