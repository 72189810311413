/* eslint-disable */
import React, {useState} from 'react';
import {categories, getNameofChannel, getChannelsPerCategory, checkForHardConstraints, isBothMantExpValue} from '../../utils.js';
// icons
import {BsChevronDoubleRight} from 'react-icons/bs';
import {BsChevronDoubleLeft} from 'react-icons/bs';
import { MdAddCircle } from "react-icons/md";
// css
import './VisualVariablesTable.scss';

const VisualVariablesTable = (props) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const isDisabled = (channel, column) => {
    if(column.key === 'mantissa' && (channel === 'row' || channel === 'column')) return true;
    const disabled = checkForHardConstraints(channel, column, props.mark, props.digitsMantissa);
    return disabled.disable;
  };

  const displayMessage = (channel, column) => {
    const disabled = checkForHardConstraints(channel, column, props.mark, props.digitsMantissa);
    return disabled.message;
  };

  // Event handler to update visual variables' state
  const handleChange = (e) => {
    // Split the string on the ':' character
    const parts = e.target.name.split(':');
    const column = parts[0];
    const channel = parts[1];
    let updateValue;
    if ((column === 'exponent' && props.visualVariables[channel] === 'mantissa') || (column === 'mantissa' && props.visualVariables[channel] === 'exponent') ) {
      updateValue = 'exponent+mantissa';
    } else if ((column === 'exponent' && props.visualVariables[channel] === 'exponent+mantissa')) {
      updateValue = 'mantissa'; // uncheck exponnet
    } else if ((column === 'mantissa' && props.visualVariables[channel] === 'exponent+mantissa')) {
      updateValue = 'exponent'; // uncheck mantissa
    } else {
      updateValue = e.target.value;
    }
    props.updateVisualVariables(channel, updateValue);
  };

  return (
    <div className="tab" id="visualVariables">
      <div className="tabMenu" style={{backgroundColor: isExpanded ? 'rgb(210 211 243)' : '#dfe1f1'}}>
        <span onClick={toggleSidebar}>
          {isExpanded ? <BsChevronDoubleLeft id="expandIcon"/> : <BsChevronDoubleRight id="expandIcon"/>}
        </span>
        <h5 className="rotatedText">Visual Variables</h5>
      </div>
      {isExpanded &&
      <table>
        <colgroup>
          <col span="1" />
          <col span="1" className="column-group-separator"/>
          <col span={Object.keys(props.selectedColumns).length} className="column-group-separator"/>
        </colgroup>
        <thead>
          <tr>
            <th className="header-group" colSpan="2"></th>
            {Object.keys(props.selectedColumns).map((dim) =>
              <th key={dim} className="header-group">{props.selectedColumns[dim].key}</th>,
            )}
          </tr>
        </thead>
        <tbody>
          {/* VISUAL VARIABLES*/}
          {categories.map((cat, index) => (
            <React.Fragment key={index}>
              <tr className="categoryChannel">
                <td colSpan={Object.keys(props.selectedColumns).length + 2}>{cat}</td>
              </tr>
              {getChannelsPerCategory(cat).map((ch, chIndex) => (
                <tr key={chIndex}>
                  <td><img src={'/icons-no-border/' + ch + '-icon.png'} alt={ch} /></td>
                  <td>
                    {
                      getNameofChannel(ch)
                    }
                    {
                      (getNameofChannel(ch) === 'Length' && props.mark === 'line') && <> (width)</>
                    }
                  </td>
                  {Object.keys(props.selectedColumns).map((dim) =>
                    <td key={dim}
                      className={isDisabled(ch, props.selectedColumns[dim]) ? 'disable-cell' : ''}
                      title={isDisabled(ch, props.selectedColumns[dim]) ? displayMessage(ch, props.selectedColumns[dim]) : ''}>
                      <input
                        type="checkbox"
                        name={props.selectedColumns[dim].key+ ':' + ch}
                        value={props.visualVariables[ch] === props.selectedColumns[dim].key ? '' : props.selectedColumns[dim].key}
                        checked={props.visualVariables[ch] === 'exponent+mantissa' ? ['mantissa', 'exponent'].includes(props.selectedColumns[dim].key) : props.visualVariables[ch] === props.selectedColumns[dim].key}
                        disabled={isDisabled(ch, props.selectedColumns[dim])}
                        onChange={handleChange}
                      />
                      {props.visualVariables[ch] === 'exponent+mantissa' && props.selectedColumns[dim].key === 'mantissa' && (ch === "positionX" || ch === "positionY") &&
                        <>
                          <MdAddCircle id='plus' title='Exponent plus mantissa.'/>
                          <div id='connection-line'></div>
                        </> 
                        }
                      {props.visualVariables[ch] !== 'exponent+mantissa' && props.selectedColumns[dim].key === 'mantissa' && (ch === "positionX" || ch === "positionY") &&
                         <>
                         <MdAddCircle id='plus-inactive' title='Exponent plus mantissa.'/>
                         <div id='connection-line'></div>
                        </> 
                      }
                      
                    </td>,
                  )}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      }
    </div>
  );
};

export default VisualVariablesTable;
