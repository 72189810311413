// Based off this example for using DuckDB-Wasm:
// https://github.com/duckdb-wasm-examples


import * as duckdb from '@duckdb/duckdb-wasm';

import duckdb_wasm from '@duckdb/duckdb-wasm/dist/duckdb-mvp.wasm';
import duckdb_wasm_eh from '@duckdb/duckdb-wasm/dist/duckdb-eh.wasm';


// const DUCKDB_BUNDLES = {
//   mvp: {
//     mainModule: duckdb_wasm,
//     mainWorker: new URL(
//         '@duckdb/duckdb-wasm/dist/duckdb-browser-mvp.worker.js',
//         import.meta.url,
//     ).toString(),
//   },
//   eh: {
//     mainModule: duckdb_wasm_eh,
//     mainWorker: new URL(
//         '@duckdb/duckdb-wasm/dist/duckdb-browser-eh.worker.js',
//         import.meta.url,
//     ).toString(),
//   },
// };

const DUCKDB_BUNDLES = {
  mvp: {
    mainModule: 'https://cdn.jsdelivr.net/npm/@duckdb/duckdb-wasm/dist/duckdb-mvp.wasm',
    mainWorker: '/customWorker.js', // Path to the custom worker script in the public folder
  },
  eh: {
    mainModule: 'https://cdn.jsdelivr.net/npm/@duckdb/duckdb-wasm/dist/duckdb-eh.wasm',
    mainWorker: '/customWorker.js', // Path to the custom worker script in the public folder
  },
};

const initDB = async () => {
  // Select a bundle based on browser checks
  const bundle = await duckdb.selectBundle(DUCKDB_BUNDLES);
  // Instantiate the asynchronus version of DuckDB-wasm
  const worker = new Worker(bundle.mainWorker);
  const logger = new duckdb.ConsoleLogger();
  const db = new duckdb.AsyncDuckDB(logger, worker);
  await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
  return db;
};


export default initDB; // so we can import this elsewhere
