/* eslint-disable require-jsdoc */
/* eslint-disable */
import React, {useState, useEffect} from 'react';
// css
import './DataMenu.scss';
// icons
import {BsChevronDoubleRight, BsChevronDoubleLeft} from 'react-icons/bs';
// functions
import {displayRange} from '../../data.js';

// DuckDB import
import dataDB from '../../db/loadData.js';

const DataMenu = (props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  // State to hold the selected option
  const [selectedOption, setSelectedOption] = useState('');
  const [metadataArray, setMetadataArray] = useState([]);
  const [noMetadata, setNoMetadata] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileSuitable, setIsFileSuitable] = useState(false);



  useEffect(() => {
    if (Object.entries(props.metadata).length === 0) return;
    const array = Object.entries(props.metadata);
    setMetadataArray([...array]);
    setNoMetadata(false);
  }, [props.metadata]);

  useEffect(() => {
  }, [props.selectedColumns]);

  useEffect(() => {
  }, [props.datasetName]);

  useEffect(() => {
    console.log(props.dataset);
  }, [])

  
  const disableMark = (mark) => {
      if(mark === "line") {
        return !(metadataArray.some(([key, value]) => value.type === "nominal") || metadataArray.some(([key, value]) => value.type === "date"));
      } else if (mark==="area"){
        return !(metadataArray.some(([key, value]) => value.type === "date"));
      } else {
        return false;
      }
  }

  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check file suitability (e.g., file type and size)
      const isSuitable = checkFileSuitability(file);
      setIsFileSuitable(isSuitable);
      setSelectedFile(file);
      if(isSuitable){
        props.uploadFile(file)
      };
    }
  };

  const checkFileSuitability = (file) => {
    // Check if the file extension is .parquet
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
    if (fileExtension === 'parquet') {
      return true;
    }
    alert('File is not suitable. Please select a .parquet file.');
    return false;
  }





  // Handle item selection
  const handleDataFieldChange = (itemKey) => {
    // find field in metadata based on itemKey
    const field = {key: itemKey, ...props.metadata[itemKey]};

    if (props.selectedColumns === null) return;
    // If selected, remove it from the array
    if (props.selectedColumns.some((column) => Object.keys(field).every((key) => field[key] === column[key]))) {
      const updatedColumns = props.selectedColumns.filter((col) => col.key !== itemKey);
      props.updateColumns([...updatedColumns]);
    // else add it
    } else {
      // If not selected, add it to the array
      props.updateColumns([...props.selectedColumns, field]);
    }
  };

  const handleOMVChange = (omv) => {
    // call function from parent to update data
    props.updateOMV(omv.target.value);
  };


  // Select first dataset
  const goExploring = () => {
    // call function from parent to update data
    props.updateExploring(true);
  };

  // // Handler for changing the select value
  // const handleChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  // Function to activate or change the select option when the button is clicked
  const loadData = (event) => {
    // Set the selected option to a specific value
    props.updateDataset(event.target.value);
  };

  const handleChangeMark = (e) => {
    props.updateMark(e.target.value);
  };

  const handleChangeDigits = (e) => {
    props.updateDigitsMantissa(Number(e.target.value));
  };

  const printOmvData = () => {
    const omvD = [];
    metadataArray.map(([key, value]) => {
      if (value.type == 'quantitative' && typeof value.range === 'bigint') {
        if (key != '__index_level_0__') {
          omvD.push(key);
        }
      }
    });
    const formattedString = omvD.slice(0, -1).join(', ') + ', and ' + omvD[omvD.length - 1];
    return formattedString;
  };
  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const sidebarStyle = {
    width: isExpanded ? 'auto' : '0px',
    transition: 'width 0.2s ease',
  };

  const menuExpanded = {
    maxWidth: isExpanded ? '260px' : '41px',
  };


  return (
    <div className="dataMenu" style={menuExpanded}>
      <div className="tabMenu" style={{backgroundColor: isExpanded ? 'rgb(210 211 243)' : '#dfe1f1'}}>
        <span onClick={toggleSidebar}>
          {isExpanded ? <BsChevronDoubleLeft id="expandIcon"/> : <BsChevronDoubleRight id="expandIcon"/>}
        </span>
        <h5 className="rotatedTextData">Data</h5>
      </div>
      {isExpanded &&
        <div className="menuContainer" style={sidebarStyle}>
          <h5 className="menuTitle">Dataset</h5>
          <br/>
          <input type="file" id="file-input" onChange={handleFileChange} />
          <label htmlFor="file-input" className="custom-file-upload">
            Add New Dataset
          </label>
          <p className='smaller-text'>{props.messageUpload}</p>
          <p></p>
          <div className="titleLine"></div>
          <br/>
          <label>Choose a dataset: </label>
      <select name="selectedValue" defaultValue={props.datasetName} onChange={loadData}>
        {/* Placeholder option */}
        <option value="" disabled>
                Available datasets
        </option>
        {props.availableDatasets.map((dataset) => (
          <option key={dataset} value={dataset}>
           {dataset}
          </option>
        ))}
      </select>

      <br/>
      {!noMetadata && 
      <>
          <label>METADATA</label>
          <table>
            <thead>
              <tr>
                <th>Columns</th>
                <th>Type</th>
                <th>Range</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(props.metadata).sort((a, b) => a[1].type.localeCompare(b[1].type)).map(([key, value]) => (
                key != '__index_level_0__' &&
                <tr key={key}>
                  <td>{key}</td>
                  <td>{value.type}</td>
                  <td>{displayRange(value.type, value.range)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* <label>
            <b>Order-of-Magnitude Values (OMVs):</b> {printOmvData(props.metadata)}.
          </label> */}

          <div className="menuField">
            <label>CHOOSE OMV:</label><hr/>
            <label>
                    I want to seperate
              <select name="selectedValue" onChange={handleOMVChange} defaultValue={props.omv}>
                {metadataArray.map(([key, value]) => (
                  // if data are OMVs
                  (value.type == 'quantitative' && typeof value.range === 'bigint') &&
                        <option
                          key={key}
                          value={key}
                        >
                          {key}
                        </option>
                ))}
              </select>
                into mantissa and exponent.
            </label>

          </div>
          <div id="columnField">
            <label>CHOOSE OTHER COLUMNS:</label><hr/>
            <label>
                I want to visualize {props.omv} per:
            </label>

            <div className="dataFields">
              <ul>
                {metadataArray.map(([key, value]) => (
                  !(value.type === 'quantitative' && typeof value.range === 'bigint') &&
                            <li key={key}>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={props.selectedColumns.some((obj) => obj['key'] === key)}
                                  onChange={() => handleDataFieldChange(key)}
                                />
                                {key} ({value.type})
                              </label>
                            </li>
                ))}
              </ul>
            </div>
            {/* <label className="labelLarge">Mantissa and Exponent</label><hr/>
            <div className="menuField">
              <label>
          Digits for mantissa:
                <br/>
                <select name="digits" value={props.digitsMantissa} onChange={handleChangeDigits} >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </label>
            </div> */}
          </div>
          <div>
            <label className="labelLarge">Choose Mark:</label><hr></hr>
            <div className="markSelection">
              <label className="labelMark">
                <input
                  type="radio"
                  name="mark"
                  value="point"
                  checked={props.mark === 'point'}
                  onChange={handleChangeMark}
                />
                <img className="markIcons" width="100%" height="100%" src="/marks/point.png"/>
          Point
              </label>
              <label className="labelMark">
                <input
                  type="radio"
                  name="mark"
                  value="line"
                  checked={props.mark === 'line'}
                  disabled={disableMark("line")}
                  onChange={handleChangeMark}
                />
                <img className="markIcons" width="100%" height="100%" src="/marks/line.png"/>
          Line
              </label>
              <label className="labelMark">
                <input
                  type="radio"
                  name="mark"
                  value="area-line"
                  checked={props.mark === 'area-line'}
                  disabled={disableMark("area")}
                  title={disableMark("area") ? "The area mark doesn't support the selected type of data." : ""}
                  onChange={handleChangeMark}
                />
                <img className="markIcons" width="100%" height="100%" src="/marks/area-line.png"/>
          Area-line
              </label>
            </div>

          </div>
          </>
      }
        </div>
      }
    </div>
  );
};

export default DataMenu;
